<template>
  <SidePopup
    :title="title"
    v-on:close="onClose"
    @submit="onSubmit"
    :loading="isLoading"
    routeback="/fornecedor/cadastro/pagina-estatica"
  >
    <h4 class="text-center">Dados básicos</h4>
    <LineDivider></LineDivider>
    <div ref="codeMirror"></div>
    <FormField
      label="Titulo da página"
      v-model="form.titulo"
      :validation="$v.form.titulo"
    />
    <FormField
      label="Slug da página"
      v-model="form.slug"
      :validation="$v.form.slug"
      @change="onChangeSlug"
      help="O slug é o nome que vai na barra de endreço, este que por sua vez dará acesso direto a página"
    />
    <FormField
      label="Ordenação"
      v-model="form.ordenacao"
      :validation="$v.form.ordenacao"
      typeField="number"
      help="Ordem em que as páginas estáticas serão exibidas nas listas dos aplicativos"
    />
    <FormSelect
      label="Local onde a página será exibida"
      v-model="form.tag"
      :validation="$v.form.tag"
      :options="tags"
      :multiple="true"
    />
    <FormSwitch
      v-model="form.link_visivel"
      label="O Link desta página ficará visível?"
      class="mt-0"
    />
    <FormSwitch
      v-model="form.apenas_logado"
      label="Apenas para usuários logados"
      :disabled="!form.link_visivel"
      class="mt-0"
    />
    <FormSwitch
      v-model="form.pagina_home"
      label="Essa página será a home do site"
      class="mt-0"
    />
    <h4>Conteúdo</h4>
    <FormRadioGroup
      v-model="form.tipo_conteudo"
      inline
      :radios="tipoConteudo"
    />
    <small>
      Para aprender mais sobre o formato do conteúdo veja as
      <a
        :href="
          form.tipo_conteudo == 'STATIC_MD_PAGE'
            ? 'https://pt.wikipedia.org/wiki/Markdown'
            : 'https://pt.wikipedia.org/wiki/HTML'
        "
        target="_blank"
      >
        dicas de formatação
      </a>
    </small>
    <Simplemde
      v-if="form.tipo_conteudo == 'STATIC_MD_PAGE'"
      v-model="form.conteudo"
      ref="markdownEditor"
    />
    <Codemirror
      v-else
      v-model="form.conteudo"
      :options="editorOptions"
    ></Codemirror>
    <template v-slot:buttons>
      <FormButton text="Salvar" submit type="submissao" />
    </template>
  </SidePopup>
</template>

<script>
import SidePopup from '@/components/SidePopup'
import LineDivider from '@/components/LineDivider'
import FormField from '@/components/form/FormField'
import FormButton from '@/components/form/FormButton'
import FormSelect from '@/components/form/FormSelect'
import FormSwitch from '@/components/form/FormSwitch'
import FormRadioGroup from '@/components/form/FormRadioGroup'
import Codemirror from '@/components/Codemirror'
import { mapGetters } from 'vuex'
import { required, numeric } from 'vuelidate/lib/validators'
import {
  PAGINA_ESTATICA_CREATE,
  PAGINA_ESTATICA_UPDATE,
  PAGINA_ESTATICA_LOAD,
  TAGS
} from '@/store/actions/paginaEstatica'
import Simplemde from 'vue-simplemde'
import StringUtils from '../../../utils/stringUtils'

export default {
  name: 'PaginaEstaticaForm',
  components: {
    SidePopup,
    LineDivider,
    FormField,
    FormButton,
    FormSelect,
    FormSwitch,
    FormRadioGroup,
    Simplemde,
    Codemirror
  },
  data: () => ({
    editorOptions: {
      lineNumbers: true,
      mode: 'htmlmixed'
    },
    mode: 'add',
    isLoading: false,
    tags: TAGS,
    tipoConteudo: [
      { value: 'STATIC_MD_PAGE', label: 'Markdown' },
      { value: 'STATIC_HTML_PAGE', label: 'HTML' }
    ],
    slugEdited: false,
    form: {
      ordenacao: 0,
      tag: [TAGS[0].value, TAGS[1].value],
      link_visivel: true,
      apenas_logado: false,
      tipo_conteudo: 'STATIC_MD_PAGE',
      conteudo: ''
    }
  }),
  validations: {
    form: {
      titulo: { required },
      slug: { required },
      tag: { required },
      ordenacao: { numeric }
    }
  },
  methods: {
    onClose(routeBack = false) {
      this.isLoading = false
      this.$emit('close')
      if (routeBack) {
        this.$router.replace('/fornecedor/cadastro/pagina-estatica')
      }
    },
    onSubmit() {
      const options = {
        title: 'Nova página home',
        text: `Caso exista outra página cadastrada como home, será alterada, e somente essa página será mantida como home. Deseja prosseguir com a alteração?`
      }
      if (this.form.pagina_home) {
        this.confirmSwal(options, () => this.submit())
      } else {
        this.submit()
      }
    },
    submit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return
      this.isLoading = true
      let paginaEstatica = {
        ...this.form
      }
      if (this.mode == 'edit' && paginaEstatica.idconteudoestatico) {
        this.$store
          .dispatch(PAGINA_ESTATICA_UPDATE, paginaEstatica)
          .then(() => {
            this.$vueOnToast.pop(
              'success',
              'Página estática atualizada com sucesso'
            )
            this.onClose(true)
          })
          .catch(err => {
            const erro =
              err.response.data.Mensagem ||
              err.response.data ||
              'Erro ao atualizar página estática'
            this.$vueOnToast.pop('error', erro)
            this.isLoading = false
          })
      } else {
        this.$store
          .dispatch(PAGINA_ESTATICA_CREATE, paginaEstatica)
          .then(() => {
            this.$vueOnToast.pop(
              'success',
              'Página estática criada com sucesso'
            )
            this.onClose(true)
          })
          .catch(err => {
            const erro =
              err.response.data.Mensagem ||
              err.response.data ||
              'Erro ao criar página estática'
            this.$vueOnToast.pop('error', erro)
            this.isLoading = false
          })
      }
    },
    getData(idconteudoestatico) {
      this.isLoading = true
      this.$store
        .dispatch(PAGINA_ESTATICA_LOAD, idconteudoestatico)
        .then(data => {
          this.form = data
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
          this.onClose()
        })
    },
    onChangeSlug() {
      this.slugEdited = true
      this.form.slug = StringUtils.normalizeUrl(this.form.slug)
    }
  },
  computed: {
    ...mapGetters([
      'getFornecedorId',
      'moduloFornecedorAtivo',
      'getFornecedorTag'
    ]),
    usesFidelityApp() {
      return this.getFornecedorTag['uses-fidelity-app']
    },
    title() {
      return this.mode == 'edit'
        ? 'Editar página estática'
        : 'Nova página estática'
    }
  },
  created() {
    if (!this.usesFidelityApp) {
      this.tags = this.tags.filter(t => t.type !== 'APPFID')
    }
    let { idconteudoestatico } = this.$route.params
    if (idconteudoestatico !== 'novo') {
      this.mode = 'edit'
      this.getData(idconteudoestatico)
    }
  },
  mounted() {},
  watch: {
    'form.link_visivel': function(newValue) {
      if (!newValue) {
        this.form.apenas_logado = false
      }
    },
    'form.titulo': function(newValue) {
      if (!this.slugEdited) {
        this.form.slug = StringUtils.normalizeUrl(newValue)
      }
    }
  }
}
</script>

<style>
@import '~simplemde/dist/simplemde.min.css';
</style>
