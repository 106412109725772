<template>
  <div ref="codeMirror"></div>
</template>

<script>
import 'codemirror/lib/codemirror.css'
import CodeMirror from 'codemirror'
import 'codemirror/mode/htmlmixed/htmlmixed'

export default {
  props: ['value', 'options'],
  mounted() {
    this.editor = CodeMirror(this.$refs.codeMirror, {
      ...this.options,
      value: this.value
    })
    this.editor.on('blur', () => {
      this.$emit('input', this.editor.getValue())
    })
  },
  watch: {
    value(newValue) {
      if (this.editor) {
        this.editor.setValue(newValue)
      }
    }
  },
  beforeDestroy() {
    if (this.editor) {
      this.editor.toTextArea()
    }
  }
}
</script>
